<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        xl="12"
        md="8"
      >
        <ecommerce-statistics :data="data.statisticsItems" />
      </b-col>
        <b-col
            xl="12"
            md="8"
        >
            <invoice-summery :data="data.invoice_summary" />
        </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import $ from 'jquery';
import { getUserData } from '@/auth/utils'
import EcommerceStatistics from './EcommerceStatistics.vue'
import InvoiceSummery from './InvoiceSummery.vue'
import {DASHBOARD_ISP} from "@core/services/api"
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    EcommerceStatistics,
    InvoiceSummery
  },
  data() {
    return {
      isp_id:null,
      data: {},
    }
  },
  created() { 
    this.ispList(this.isp_id);
  },
  methods: {
  ispList(ispID) { 
      this.$http.get(process.env.VUE_APP_BASEURL + DASHBOARD_ISP+'?isp_id='+ispID)
      .then(response => {
        this.data = response.data.data
        const userData = getUserData()
      })
    }
  }, 
  mounted() {  
    this.isp_id = $( "#ispListId option:selected" ).val();
    this.ispList(this.isp_id)
    let instance = this; 
    $("#ispListId").change(function(){
      this.isp_id = $(this).children("option:selected").val(); 
      instance.ispList(this.isp_id) 
    });      
  }, 
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
